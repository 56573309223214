import province from "./province";
import district from "./district";
import subdistrict from "./subdistrict";


export default {
    province,
    district,
    subdistrict,
    
};
